.login-area-button-wrapper {
  padding: 5%;
  border: 2px #aea1ff solid;
  border-radius: 12px;
  display: flex;
  margin-top: 5%;
  background-color: #fff;
  flex-direction: column;
  height: 40vh;
  justify-content: space-between;
}

.login-button-wrapper {
  margin-top: 15%;
  justify-content: flex-end;
  display: flex;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-img {
  background-image: url(../../assets/images/boid-image.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  width: 100%;
  min-width: 900px;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  transform: rotate(-60deg);
  z-index: -10;
  margin-top: 17.5%;
  margin-right: -25%;
}

.landing-page-title {
  font-family: 'ProximaNovaBold';
  color: #575c98;
}

.login-header {
  font-family: 'ProximaNovaRegular';
  color: #575c98;
}

.sig-logo {
  background: url('../../assets/images/sig-icon-1.webp');
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100px;
  background-size: contain;
  border: 0;
  outline: 0;
  margin-right: 15px;
}

.heading-wrapper {
  display: flex;
  flex-direction: row;
  width: 95vw;
  align-items: center;
  padding: 1% 0 0 5%;
  background-color: white;
}
