.wrapper{
    overflow: hidden;
}

.slack-button{
    align-items:center; 
    color:#000;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;
    display:inline-flex;
    font-family:Lato, sans-serif;
    font-size:16px;
    font-weight:600; 
    height: 48px;
    justify-content:center;
    text-decoration:none;
    width:256px;
}
.slack-button-svg{
    height:20px;
    width:20px;
    margin-right:12px
}
.landing-page-wrapper{
    text-align: center;
}

.gallery-wrapper{
    margin: 0 2% 0 2%;
    min-height: 150vh;
}

.toolbar-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
    margin-bottom: 0.5%;
}