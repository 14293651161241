body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #E2DFF4; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "ProximaNovaRegular";
  src: local("ProximaNovaRegular"),
   url("./fonts/proximanova-regular.otf") format("truetype");
  font-weight: normal;
  }
  @font-face {
    font-family: "ProximaNovaBold";
    src: local("ProximaNovaBold"),
     url("./fonts/proximanova-bold.otf") format("truetype");
    font-weight: bold;
    }
  
