.display-text{
    color: white;
    /* font-size: 60px; */
    margin-top: 5%;
}

.load-indicator-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 15%;
}